.body-android {
  .home-pizzahut {
    top: calc(calc(100vh - calc(100vh - 100%)) - 93px) !important;
  }

  .continue__as__guest__wrapper_sign_in,
  .continue__as__guest__wrapper_sign_up {
    top: calc(calc(100vh - calc(100vh - 100%)) - 102px) !important;
  }

  .sign__up {
    height: calc(calc(100vh - calc(100vh - 100%)) - 266px) !important;
  }
  // date-time-popup

  .__self_content.all__checkbox {
    max-height: calc(100vh - 420px) !important;
  }

  .__self_continue {
    top: calc(calc(100vh - calc(100vh - 100%)) - 83px) !important;
  }
  // deliver selft component
  .delivery-pizzahut {
    .__self__continue {
      top: calc(calc(100vh - calc(100vh - 100%)) - 84px) !important;
    }
  }
}

.body-ios {
  max-height: 100vh !important;
  overflow: hidden;


  .home-pizzahut {
    top: calc(100vh - 200px) !important;
  }

  .continue__as__guest__wrapper_sign_in,
  .continue__as__guest__wrapper_sign_up {
    top: calc(100vh - 200px) !important;
  }

  .sign__up {
    height: calc(100vh - 366px) !important;
  }
  // date-time-popup

  .__self_content.all__checkbox {
    max-height: calc(100vh - 460px) !important;
    overflow: auto;
  }

  .__self_continue {
    top: calc(100vh - 183px) !important;
  }
  // deliver selft component
  .delivery-pizzahut {
    .__self__continue {
      top: calc(100vh - 184px) !important;
    }
  }
}
