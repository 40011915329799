/*--------------------------------------
    DM Sans Regular
---------------------------------------*/

@font-face {
  font-family: "DM Sans";
  src: url("./dm_sans/DMSans-Regular.woff2") format("woff2"), url("./dm_sans/DMSans-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

/*--------------------------------------
    DM Sans Medium
---------------------------------------*/

@font-face {
  font-family: "DM Sans";
  src: url("./dm_sans/DMSans-Medium.woff2") format("woff2"), url("./dm_sans/DMSans-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

/*--------------------------------------
    DM Sans Bold
---------------------------------------*/

@font-face {
  font-family: "DM Sans";
  src: url("./dm_sans/DMSans-Bold.woff2") format("woff2"), url("./dm_sans/DMSans-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

/*--------------------------------------
    Oswald Bold
---------------------------------------*/

@font-face {
  font-family: "Oswald";
  src: url("./oswald/Oswald-Bold.woff2") format("woff2"), url("./oswald/Oswald-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

/*--------------------------------------
    Roboto Regular
---------------------------------------*/

@font-face {
  font-family: "Roboto";
  src: url("./roboto/Roboto-Regular.woff2") format("woff2"), url("./roboto/Roboto-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

/*--------------------------------------
    Roboto Medium
---------------------------------------*/

@font-face {
  font-family: "Roboto";
  src: url("./roboto/Roboto-Medium.woff2") format("woff2"), url("./roboto/Roboto-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

/*--------------------------------------
    Roboto Bold
---------------------------------------*/

@font-face {
  font-family: "Roboto";
  src: url("./roboto/Roboto-Bold.woff2") format("woff2"), url("./roboto/Roboto-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
